import { loadScripts } from '@uvocore-front/utils';

const init = () => {
  window.NotifyWidget({
    siteId: '99',
    container: '.NotifyWidget',
  });
};

if (document.querySelector('.NotifyWidget')) {
  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    loadScripts('/assets/js/notifyWidget.min.js').then(() => {
      init();
    });
  }
}
